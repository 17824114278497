import styled from 'styled-components';
import { themeGet } from 'styled-system';

const GalleryWrapper = styled.div`
  background-color: ${themeGet('colors.paletteYellow')};
  padding: 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (max-width: 1440px) {
    padding: 0 0 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 0;
    flex-wrap: wrap;
  }
`;

export const GalleryCard = styled.div`
  flex: 1;
  margin: 30px 0 30px 0;
  background-color: ${themeGet('colors.paletteOrange')};
  min-width: 300px;
  max-width: 300px;

  video {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
`;

export const CardTitle = styled.div`
  font-weight: normal;
  font-style: normal;
  font-size: 26px;
  font-family: 'Gilroy-ExtraBold', 'Raleway', sans-serif;
  background-color: ${themeGet('colors.paletteOrange')};
  color: ${themeGet('colors.white')};
  padding: 10px 20px 30px 20px;
  margin: 0;

  span {
    display: block;
    font-size: 20px;
    color: ${themeGet('colors.white')};
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
  }
`;

export const CardPrice = styled.div`
  position: absolute;
  border-radius: 16px;
  padding: 8px 8px;
  margin: 8px 20px;
  background:rgba(0,0,0,0.50);
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  font-family: 'Gilroy-Light', 'Raleway', sans-serif;
  color: #FFF;
`;

export default GalleryWrapper;
