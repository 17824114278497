import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import GalleryWrapper, { GalleryCard, CardTitle, CardPrice } from './banner.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        sellers {
          edges {
            node {
              id
              isFeatured
              presentationName
              imageUrl
              username
              pricing {
                id
                currency {
                  prefix
                }
                price
              }
            }
          }
        }
      }
    }
  `);

  const GalleryItem = ( node, galleryName ) => {
    const {
      id,
      username,
      imageUrl,
      presentationName,
      pricing
    } = node;

    return (
      <GalleryCard key={`${galleryName}_key${id}`}>
        <a
          href={`/${username}`}
          className="item_wrapper"
        >
          {
            GalleryPrice(pricing)
          }
          <Image
            src={imageUrl}
            alt={presentationName}
            className="carousel_img"
          />
          <CardTitle>
            {username}
          </CardTitle>
        </a>
      </GalleryCard>
    )
  }

  const GalleryPrice = ( pricing ) => {

    if (pricing.length === 0) {
      return <Fragment />
    }

    const { price, currency } = pricing[0];

    return (
      <CardPrice>{ currency.prefix } { price }</CardPrice>
    )
  }

  const { sellers } = data.api;

  return (
    <Fragment>
      <GalleryWrapper id="featured">
        <Fragment>
          {
            sellers.edges.map(seller => {
              if (seller.node.isFeatured) {
                return GalleryItem(seller.node, 'featured');
              }
              return <Fragment key={`featured_${seller.node.id}`}/>
            })
          }
        </Fragment>
      </GalleryWrapper>
      <GalleryWrapper id="gallery">
        <Fragment>
          {
            sellers.edges.map(seller => {
              return GalleryItem(seller.node, 'gallery');
            })
          }
        </Fragment>
      </GalleryWrapper>
    </Fragment>
  );
};

export default Banner;
