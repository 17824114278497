import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import { SectionHeader } from '../interior.style';
import SectionWrapper, {
  DonationWrapper,
  TextWrapper,
  ImageWrapper,
  ImagesWrapper,
  DecorationWrapper,
} from './donation.style';

const Donation = ({campaign}) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        projectData {
          campaigns {
            title
            slogan
            header
            description_a
            description_b
            thumb_url {
              publicURL
            }
            logo_url {
              publicURL
            }
          }
        }
      }
    }
  `);

  var campaignId = (campaign === "outback") ? 1: 0;
  const { title, slogan, thumb_url, logo_url, header, description_a, description_b } = data.interiorJson.projectData.campaigns[campaignId];

  return (
    <SectionWrapper id="donation">
      <Container width="1360px">
        <Fade bottom>
          <SectionHeader>
            <Heading as="h5" content={title} />
            <Heading content={slogan} />
          </SectionHeader>
        </Fade>

        <DonationWrapper>

          <TextWrapper>
            <Heading as="h3" content={header} />
            <Text content={description_a} />
            <Text content={description_b} />
          </TextWrapper>

          <ImagesWrapper>
            <DecorationWrapper>
              <Image src={thumb_url.publicURL} alt="Doações" />
            </DecorationWrapper>

            <ImageWrapper>
              <Image src={logo_url.publicURL} alt="Entidade beneficiada" />
            </ImageWrapper>
          </ImagesWrapper>

        </DonationWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Donation;
