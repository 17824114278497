import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import loadable from '@loadable/component';
import { interiorTheme } from 'common/src/theme/interior';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Interior/Navbar';
import Header from '../containers/Interior/Header';
import Banner from '../containers/Interior/Banner';
import Feature from '../containers/Interior/Feature';
import Donation from '../containers/Interior/Donation';
import Footer from '../containers/Interior/Footer';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from '../containers/Interior/interior.style';
import SEO from '../components/seo';
import HelloFan from '../components/HelloFan';

const Reaction = loadable(() => import('../containers/Interior/Reaction'));

export default () => {
  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <SEO title="Página Inicial" />
        <ResetCSS />
        <GlobalStyle />

        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />  
            </DrawerProvider>
            <HelloFan />
          </Sticky>
          <ContentWrapper>
            <Header />
            <Reaction />
            <Banner />
            <Feature />
            <Donation />
          </ContentWrapper>
          <Footer />
        </InteriorWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
