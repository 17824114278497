import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';

const Header = ({
  SectionWrapper,
  row,
  col,
  title,
  description,
  campaign,
}) => {

  if(campaign==='outback') {
    title.content = "Outback título"
    description.content = "Texto call to action do outback."
  }

  return (
    <Box
      {...SectionWrapper}
    >
      <Container>
        <Box {...row}>
          <Box {...col}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object,
};

Header.defaultProps = {
  SectionWrapper: {
    as: 'section',
    pt: '200px',
    pb: '100px',
    overflow: 'hidden',
    className: 'mainSection',
    backgroundColor: '#000000 !important',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '44%', '44%'],
    mt: '-80px',
  },
  imageArea: {
    width: ['0%', '0%', '43%', '35%', '40%'],
    ml: 'auto',
  },
  title: {
    content: 'manda salve!',
    fontSize: ['26px', '30px', '30px', '48px', '100px'],
    lineHeight: ['26px', '30px', '30px', '48px', '90px'],
    width: ['100%', '100%', '100%', '80%', '90%'],
    color: '#FFFFFF',
    letterSpacing: '-0.01px',
    mb: '20px',
  },

  description: {
    content:
      'Presenteie e surpreenda com vídeos personalizados.',
    color: '#FFFFFF',
    lineHeight: '33px',
    mb: '10px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: "'Gilroy-Light', 'Raleway', sans-serif", 
    fontSize: ['18px', '18px', '22px', '22px', '28px'],
    width: ['100%', '70%', '80%', '90%', '100%'],
  },
  button: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  image: {
    ml: 'auto',
    mt: '70px',
  },
  btnStyleTwo: {
    minWidth: '156px',
    ml: '15px',
    bg: '#fff',
    color: 'rgb(26, 115, 232)',
  },
  textArea: {
    width: ['100%', '100%', '50%', '55%', '55%'],
  },
  discountAmount: {
    content: 'update',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    bg: 'rgb(26, 115, 232)',
  },
  discountText: {
    content: 'Lançamento em Outubro/2019',
    fontSize: '13px',
    fontWeight: '400',
    color: '#0f2137',
    mb: 0,
    as: 'span',
    ml: '10px',
  },
};

export default Header;
