import styled from 'styled-components';
import { themeGet } from 'styled-system';

const SectionWrapper = styled.section`
  padding: 80px 0 40px;
  background-color: ${themeGet('colors.paletteLightBlue')};
  margin-top: 140px;
  position: relative;
    
  @media only screen and (max-width: 767px) {
    margin-top: 42px;
    padding: 82px 0;
  }
  
  header {
    text-align: left;
  }

  h5 {
    color: ${themeGet('colors.white')};
  }
`;

export const DonationWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}
`;

export const TextWrapper = styled.div`
  flex: 1;

  margin-bottom: 80px;

  p, h3 {
    color: ${themeGet('colors.white')};
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
  }

  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 20px;
      line-height: 30px;
    }
  }

`;

export const ImagesWrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const ImageWrapper = styled.div`
  flex: 0.6;
`;

export const DecorationWrapper = styled.div`
  flex: 0.3;
  margin: 0 50px 0 50px;
`;

export default SectionWrapper;
